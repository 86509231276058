<template>
    <footer class="mt-auto ">
        <div class="container">
            <div class="region region-footer">
                <div id="block-centurionfooter"
                     class="block block-block-content block-block-contentff38e939-76bb-40d0-9aeb-f009e11639c4">
                    <div
                         class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 centtoctitle">{{$t(`Terms and Conditions`)}}</div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-3">
                                    <ul class="centtoclist">
                                        <li><a href="/assets/files/9.1-cent-cm-agreement-sept-2024-v1-1-en.pdf"
                                               target="_blank"
                                               rel="noopener noreferrer">{{ $t(`Cardmember Agreement`) }}</a></li>
                                        <li><a href="/assets/files/AMEX_Centurion_Insurance_2023-2024_Premium_Dept_ENG_05_DEC_2023.pdf"
                                               target="_blank"
                                               rel="noopener noreferrer">{{ $t(`Protection & Travel Assistance`) }}</a></li>
                                        <li><a href="/assets/files/cent-glance-bilingual-2023-003-1.pdf"
                                               target="_blank"
                                               rel="noopener noreferrer">{{ $t(`Centurion at a Glance`) }}</a></li>
                                        <li><a href="/assets/files/AMX-3487-69-04-08-23-CAM-cent.pdf"
                                               target="_blank"
                                               rel="noopener noreferrer">{{ $t(`Centurion Card Sharia Certificate`) }}</a></li>
                                        <li><a href="/assets/files/AMX-20-01-01-11-22-CAM_Equal_Monthly_Instalments_Product.pdf"
                                               target="_blank"
                                               rel="noopener noreferrer">{{ $t(`Plan It Sharia Certificate`) }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div><!-- <style type="text/css">								</style> -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import store from '@/store';


export default {
    components: {

    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
    }
}
</script>


<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.banner-cont {
    background: url(../../assets/images/centurion/centurion_en-min.png) 100% 70% no-repeat;
    background-size: cover;
    min-height: 450px;
}

table {
    width: 100%;
    border: solid 1px;
    text-align: center;
}

th {
    padding: 1em;
    border: solid 1px;
}

td {
    border: solid 1px;
    padding: 0.25em 0;
}

@media only screen and (max-width: 576px) {
    table {
        display: block;
        overflow-x: scroll;
    }
}

.phone,
.email,
.fs {
    font-size: 0.9em;
}

.phone a,
.email a {
    color: #ffffff;
    text-decoration: none;
}

.phone a:hover,
.email a:hover {
    color: #ffffff;
    text-decoration: underline;
}

.pad {
    padding: 0;
}

.brd {
    border-bottom: solid 1px #fff;
    margin: 1em 0 2em 0;
    max-width: 50%;
}

.pr-0 {
    padding-right: 0;
}

.centtoctitle {
    color: #000000;
    font-size: 2em;
}

.centtoclist,
.centtoclist a,
.centtoclist a:link,
.centtoclist a:visited,
.centtoclist a:hover {
    color: #000000;
    font-size: 1em;
    line-height: 1.5em;
}

@media only screen and (max-width: 576px) {
    .centtoclist {
        margin-bottom: 1.5em;
    }
}
</style>
